<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div class="information_portrait">
            <img v-if="user.head_portrait" :src="head_portrait"/>
        </div>
        <van-cell-group inset >
            <van-cell title="用户名" :value="user.username" />
            <van-cell title="姓名" :value="user.name" />
            <van-cell title="性别" :value="user.sex === 0 ? '男' : '女'" />
            <van-cell title="住址" :value="user.address" />
            <van-cell title="入职日期" :value="user.entry_date" />
            <van-cell title="所属企业" :value="user.enterprise_name" />
            <van-cell title="所属部门" :value="user.department_name" />
            <van-cell title="职位" :value="user.position_name" />
            <van-cell title="邮箱" :value="user.email" />
            <van-cell title="手机" :value="user.phone" />
            <van-cell title="微信通知">
                <template #right-icon>
                    <van-tag plain type="success" size="large" v-if="user.notice_wx">已开启</van-tag>
                    <van-tag plain type="danger" size="large" v-else>未开启</van-tag>
                </template>
            </van-cell>
            <van-cell title="邮件通知">
                <template #right-icon>
                    <van-tag plain type="success" size="large" v-if="user.notice_email">已开启</van-tag>
                    <van-tag plain type="danger" size="large" v-else>未开启</van-tag>
                </template>
            </van-cell>
        </van-cell-group>
    </div>

</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { my_information_request } from '@/network/user'

export default {
    name:'UserInformationComponent',
    data(){
        return {
            user: {},
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '个人信息',

        }
    },
    computed:{
        head_portrait() {
            return this.$store.state.baseURL + this.user.head_portrait
        }
    },
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            my_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.user = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
.information_portrait {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.information_portrait>img {
    width: 3rem;
    height: 3rem;
    border-radius:50%;
    object-fit: unset;
}
</style>