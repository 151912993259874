import request from './request'

//用户登录
export const staff_login_request = data => {
    return request({
        method:'POST',
        url:'staff_login',
        data
    })
}

//微信回调登录
export const wx_login_request = p => {
    return request({
        method:'get',
        url:'wx_login',
        params: p
    })
}

//获取用户个人信息
export const my_information_request = () => {
    return request({
        method:'GET',
        url:'my_information'
    })
}

//修改个人密码
export const edit_password_request = data => {
    return request({
        method:'PUT',
        url:'user/change_password',
        data: {
            old_password: data.old_password,
            new_password: data.new_password
        }
    })
}

//获取邮件验证码（修改邮箱）
export const email_erification_code_request = d => {
    return request({
        method:'POST',
        url:'user/email_erification_code',
        data: {
            password: d.password,
            email: d.email
        }
    })
}

//修改个人邮箱
export const edit_eamil_request = d => {
    return request({
        method:'PUT',
        url:'user/edit_email',
        data: {
            email: d.email,
            email_code: d.emailCode
        }
    })
}

//获取手机验证码（修改手机号）
export const phone_erification_code_request = d => {
    return request({
        method:'POST',
        url:'user/phone_erification_code',
        data: {
            password: d.password,
            phone: d.phone
        }
    })
}

//修改个人手机
export const edit_phone_request = d => {
    return request({
        method:'PUT',
        url:'user/edit_phone',
        data: {
            phone: d.phone,
            phone_code: d.phoneCode
        }
    })
}

//修改通知方式
export const edit_notice_request = d => {
    return request({
        method:'PUT',
        url:'user/edit_notice',
        data: d
    })
}

//修改个人头像
export const upload_image = file => {
    const formData = new FormData()
    formData.append("file", file)
    return request({
        method:'POST',
        url:'user/upload_head_portrait',
        data: formData
    })
}

//我的申请
export const my_apply_request = p => {
    return request({
        method:'GET',
        url:'my_apply',
        params: p
    })
}

//我的审批
export const my_examine_request = p => {
    return request({
        method:'GET',
        url:'my_examine',
        params: p
    })
}

//我的审批
export const my_cc_request = p => {
    return request({
        method:'GET',
        url:'my_cc',
        params: p
    })
}

//我的通知数量
export const my_notice_num_request = () => {
    return request({
        method:'GET',
        url:'my_notice_num'
    })
}